import { ArrowRight } from 'lucide-react'
import ChatbotRequestModal from './chatbot-request'
import { ModalTrigger } from './ui/animated-modal'
import TextShimmer from './ui/text-shimmer'
import { ConfettiButton } from './magicui/confetti'

export default function HeroCta() {
  return (
    <>
      <ChatbotRequestModal>
        <ModalTrigger>
          <ConfettiButton className="bg-transparent hover:bg-transparent">
            <span
              id="hero-cta-button"
              className="cursor-pointer animate-fade-in relative gap-1 rounded-xl text-white opacity-[0.01] ease-in-out [--animation-delay:300ms] bg-gradient-to-r from-indigo-500 to-sky-500 hover:-mt-1 hover:mb-1 transition-all text-sm md:text-base px-4 md:px-6 flex py-2">
              <TextShimmer className="text-white font-medium">
                Próbáld ki saját chatbotod ingyen
              </TextShimmer>
              <ArrowRight
                className="ml-1 mt-[1px] size-5 transition-transform duration-300"
                strokeWidth={2.2}
              />
            </span>
          </ConfettiButton>
        </ModalTrigger>
      </ChatbotRequestModal>
      <p className="-mt-2 text-xs animate-fade-in [--animation-delay:300ms] text-slate-400 opacity-[0.01] ease-in-out">
        * Bankkártya megadása nélkül
      </p>
    </>
  )
}
